import homepageclasses from "./Homepage.module.scss";
import Moment from "react-moment";
import moment from "moment-timezone";
import { useCookies } from "react-cookie";
import user from "Assets/user.svg";
import { useState } from "react";
import Cancel from "./CancelClass/cancel";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { cancelRescheduleRequest } from "Service/parent";
import { SuccessSwal } from "Reusable/utils/Toast";
import swal from "sweetalert";
import { IoIosRefresh } from "react-icons/io";

import { warningSwal } from "Reusable/utils/Toast";
import { MdOutlineModeEdit } from "react-icons/md";
import ModalTime from "./TimeZone/TimeZone";
import useStore from "store";
type Props = {
    EnrolledCourses: any;
    getHomePage: any;
};

const Courses: React.FC<Props> = ({ EnrolledCourses, getHomePage }) => {
    const { setTab, timeZone } = useStore((item: any) => item);
    const [timeZoneModal, setTimezoneModal] = useState(false);
    const Navigate = useNavigate();
    const [cookie] = useCookies(["HomepageUpdate"]);
    const [CancelModal, setCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [searchparams] = useSearchParams();
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };

    const PastTime = (endDateString: string, endTimeString: string) => {
        const givenTime = moment(`${endDateString}T${endTimeString}+05:30`).tz(
            timeZone || moment.tz.guess()
        );
        return givenTime.isBefore(moment().tz(timeZone || moment.tz.guess()));
    };

    const TimeDifference = (endDateString: string, endTimeString: string) => {
        const givenTime = moment(`${endDateString}T${endTimeString}+05:30`).tz(
            timeZone || moment.tz.guess()
        );
        const currentTime = moment().tz(timeZone || moment.tz.guess());

        // Calculate the difference
        const duration = moment.duration(Math.abs(currentTime.diff(givenTime)));
        // console.log(currentTime.format("DD-MM-YYY,hh:mm:ss"));
        // Get the total hours and minutes
        const totalHours = duration.asHours();
        const totalMinutes = duration.asMinutes();

        // Extract hours and minutes from totalHours
        const hours = Math.floor(totalHours);
        const minutes = Math.floor((totalHours - hours) * 60);

        return { hours, minutes };
    };
    const cancelClass = (e: React.MouseEvent<HTMLButtonElement>) => {
        let classId = e.currentTarget.getAttribute("custom-classid");
        let selectedClass = EnrolledCourses?.filter(
            (item: any) => item.ScheduleClassID == classId
        );

        if (selectedClass.length > 0) {
            setCancelModal(true);
            setData(selectedClass[0]);
        }
    };
    const contactUS = () => {
        setTab("ContactUs");
    };
    let nextClass = EnrolledCourses.filter(
        (item: any) =>
            item.Class_StudentStatus != 3 &&
            item.Class_StudentStatus != 4 &&
            item?.CancelRequestStatus != 1 &&
            item.ClassStatus != 3 &&
            item.ClassStatus != 4
    );

    async function attendanceHandler(body: any, Type: string) {
        setLoading(true);
        try {
            const Response = await cancelRescheduleRequest(
                "POST",
                JSON.stringify(body),
                token,
                Type
            );
            if (Response.Status === 1) {
                SuccessSwal(
                    Type == "Approve" ? "Approved" : "Rejected",
                    Response.Message
                );
                getHomePage(2, searchparams.get("StudentId"), false);
            } else {
                warningSwal("Warning", Response.Message);
            }
        } catch (err) {
            console.log(err);
            console.log(data);
            Error("Something went wrong please try again");
        } finally {
            setLoading(false);
        }
    }

    const rescheduleHandler = (e: any) => {
        let Id = e.currentTarget.getAttribute("custom-classid");
        let Type = e.currentTarget.getAttribute("custom-type");
        let selectedClass = EnrolledCourses?.filter(
            (item: any) => item.ScheduleClassID == Id
        );
        swal({
            title: `Are you sure you want to ${
                Type == "Approve" ? "Approve" : "Reject"
            } Request ?`,
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: Type == "Approve" ? "Approve" : "Reject",
                    value: true,
                    visible: true,
                    className:
                        Type == "Approve"
                            ? homepageclasses["approve-btn"]
                            : homepageclasses["reject"],
                },
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (selectedClass.length > 0) {
                    let body = {
                        ReScheduled_EndTime:
                            selectedClass[0].ReScheduled_EndTime,
                        ReScheduled_EndDate:
                            selectedClass[0].ReScheduled_EndDate,
                        ReScheduled_StartTime:
                            selectedClass[0].ReScheduled_StartTime,
                        ReScheduled_StartDate:
                            selectedClass[0].ReScheduled_StartDate,
                        ScheduleClassID: selectedClass[0].ScheduleClassID,
                        ScheduleClass_ApprovalID:
                            selectedClass[0].ScheduleClass_ApprovalID,
                    };
                    // console.log(Type);
                    // return;
                    attendanceHandler(body, Type);
                }
                // attendanceHandler();
            } else {
                //   handleCancel();
            }
        });
    };
    return (
        <div className={homepageclasses["Class-container"]}>
            {/* {loading && <Loader />} */}
            {CancelModal && (
                <Cancel
                    isOpen={CancelModal}
                    setIsOpen={setCancelModal}
                    data={data}
                    getHomePage={getHomePage}
                    studentId={searchparams.get("StudentId")}
                />
            )}
            {timeZoneModal && (
                <ModalTime
                    isOpen={timeZoneModal}
                    setIsOpen={setTimezoneModal}
                />
            )}
            <div className={homepageclasses["Class-left"]}>
                <header>
                    <div>
                        <h3>
                            Upcoming Classes{" "}
                            <button
                                className={homepageclasses["refresh-btn"]}
                                onClick={() => {
                                    getHomePage(
                                        2,
                                        searchparams.get("StudentId"),
                                        false
                                    );
                                }}>
                                <IoIosRefresh />
                            </button>
                        </h3>
                        {nextClass.length > 0 && (
                            <p>
                                Next Class is in{" "}
                                <span>
                                    {" "}
                                    {
                                        TimeDifference(
                                            nextClass[0].Scheduled_StartDate,
                                            nextClass[0].Scheduled_StartTime
                                        ).hours
                                    }{" "}
                                    Hours{" "}
                                    {
                                        TimeDifference(
                                            nextClass[0].Scheduled_StartDate,
                                            nextClass[0].Scheduled_StartTime
                                        ).minutes
                                    }{" "}
                                    Min
                                </span>{" "}
                            </p>
                        )}
                    </div>
                    <div>
                        <p>
                            Last updated on{" "}
                            <Moment
                                format="DD, MMM hh:mm a"
                                tz={timeZone || moment.tz.guess()}>
                                {cookie?.HomepageUpdate}
                            </Moment>
                        </p>
                        <p>
                            Timezone: {timeZone}{" "}
                            <button
                                className={homepageclasses["Edit-timezone"]}
                                onClick={() => setTimezoneModal(true)}>
                                <MdOutlineModeEdit />
                            </button>
                        </p>
                    </div>
                </header>
                <div className={homepageclasses["Classes"]}>
                    {EnrolledCourses.length > 0 ? (
                        EnrolledCourses.map((item: any, index: any) => {
                            const time = TimeDifference(
                                item.Scheduled_StartDate,
                                item.Scheduled_StartTime
                            );
                            return (
                                <div className={homepageclasses["Class"]}>
                                    <div className={homepageclasses["topic"]}>
                                        <img
                                            src={
                                                item.TutorImage != "NA"
                                                    ? item.TutorImage
                                                    : user
                                            }
                                            onError={(e) => {
                                                e.currentTarget.onerror = null;
                                                e.currentTarget.src = user;
                                            }}
                                            alt="tutor"
                                        />
                                        <div>
                                            <h3>{item.TutorName}</h3>
                                            <p>{item.CourseName}</p>
                                            <p>{item.BatchSize}</p>
                                        </div>
                                    </div>
                                    <div className={homepageclasses["time"]}>
                                        <h3>
                                            <Moment
                                                format="DD, MMM hh:mm a"
                                                tz={
                                                    timeZone ||
                                                    moment.tz.guess()
                                                }>
                                                {`${item.Scheduled_StartDate}T${item.Scheduled_StartTime}+05:30`}
                                            </Moment>
                                            {item.IsRescheduled === 1 &&
                                            item.ReScheduleStatus === 2 ? (
                                                item.ApproveStatus === 1 ? (
                                                    <p
                                                        className={
                                                            homepageclasses[
                                                                "approve-btn"
                                                            ]
                                                        }>
                                                        Parent Approved
                                                    </p>
                                                ) : item.ApproveStatus === 3 ? (
                                                    <p
                                                        className={
                                                            homepageclasses[
                                                                "reject"
                                                            ]
                                                        }>
                                                        Parent Rejected
                                                    </p>
                                                ) : (
                                                    ""
                                                )
                                            ) : item.IsRescheduled === 1 &&
                                              item.ReScheduleStatus === 1 ? (
                                                <p
                                                    className={
                                                        homepageclasses[
                                                            "approve-btn"
                                                        ]
                                                    }>
                                                    Rescheduled
                                                </p>
                                            ) : (
                                                item.IsRescheduled === 1 &&
                                                item.ReScheduleStatus === 3 && (
                                                    <p
                                                        className={
                                                            homepageclasses[
                                                                "reject"
                                                            ]
                                                        }>
                                                        Reschedule Rejected
                                                    </p>
                                                )
                                            )}
                                            {/* {item.ApproveStatus == 3 && (
                                                <p
                                                    className={
                                                        homepageclasses[
                                                            "reject"
                                                        ]
                                                    }>
                                                    Reschedule Rejected
                                                </p>
                                            )}
                                            {item.ApproveStatus == 1 &&
                                                item.IsRescheduled === 1 && (
                                                    <p
                                                        className={
                                                            homepageclasses[
                                                                "approve-btn"
                                                            ]
                                                        }>
                                                        Rescheduled
                                                    </p>
                                                )} */}
                                        </h3>
                                        {!PastTime(
                                            item.Scheduled_StartDate,
                                            item.Scheduled_StartTime
                                        ) ? (
                                            <p>
                                                In Next {time.hours} Hours{" "}
                                                {time.minutes} Min
                                            </p>
                                        ) : (
                                            <p>
                                                {" "}
                                                {item.JoinUrl == "NA"
                                                    ? ""
                                                    : "Ongoing"}
                                            </p>
                                        )}
                                    </div>
                                    {item.ClassStatus == 3 ||
                                    item?.CancelRequestStatus == 1 ||
                                    item.ClassStatus == 4 ? (
                                        <p
                                            className={
                                                homepageclasses["cancelled"]
                                            }>
                                            {item.CancelledByType == 1
                                                ? "Cancelled By Administrator"
                                                : item.CancelledByType == 2
                                                ? "Cancelled By RM"
                                                : item.CancelledByType == 3
                                                ? "Cancelled By Tutor"
                                                : item.CancelledByType == 4
                                                ? item.BatchSize ===
                                                  "One-to-One"
                                                    ? "Cancelled By Parent"
                                                    : "Not Attending"
                                                : "Cancelled By SalesManager"}
                                        </p>
                                    ) : item.Class_StudentStatus == 3 ||
                                      item?.Class_StudentStatus == 4 ? (
                                        <p
                                            className={
                                                homepageclasses["cancelled"]
                                            }>
                                            {item.Student_CancelledByType == 1
                                                ? "Cancelled By Administrator"
                                                : item.Student_CancelledByType ==
                                                  2
                                                ? "Cancelled By RM"
                                                : item.Student_CancelledByType ==
                                                  3
                                                ? "Cancelled By Tutor"
                                                : item.Student_CancelledByType ==
                                                  4
                                                ? item.BatchSize ===
                                                  "One-to-One"
                                                    ? "Cancelled By Parent"
                                                    : "Not Attending"
                                                : "Cancelled By SalesManager"}
                                        </p>
                                    ) : (
                                        item.IsRescheduled == 1 &&
                                        item.ApproveStatus == 2 && (
                                            <div
                                                className={
                                                    homepageclasses[
                                                        "class-btns-Approve"
                                                    ]
                                                }>
                                                <div
                                                    className={
                                                        homepageclasses["btns"]
                                                    }>
                                                    <button
                                                        className={
                                                            homepageclasses[
                                                                "approve-btn"
                                                            ]
                                                        }
                                                        custom-classid={
                                                            item.ScheduleClassID
                                                        }
                                                        custom-type={"Approve"}
                                                        onClick={
                                                            rescheduleHandler
                                                        }>
                                                        Approve
                                                    </button>
                                                    {item.BatchSize ===
                                                        "One-to-One" && (
                                                        <button
                                                            className={
                                                                homepageclasses[
                                                                    "reject"
                                                                ]
                                                            }
                                                            custom-type={
                                                                "Reject"
                                                            }
                                                            onClick={
                                                                rescheduleHandler
                                                            }
                                                            custom-classid={
                                                                item.ScheduleClassID
                                                            }>
                                                            Reject
                                                        </button>
                                                    )}
                                                </div>
                                                <p>
                                                    {" "}
                                                    Reschedule Date{" "}
                                                    <Moment
                                                        format="DD, MMM hh:mm a"
                                                        tz={timeZone}>
                                                        {`${item.ReScheduled_StartDate}T${item.ReScheduled_StartTime}+05:30`}
                                                    </Moment>
                                                </p>
                                            </div>
                                        )
                                    )}
                                    {/* </div> */}

                                    {item.ClassStatus != 3 &&
                                        item?.CancelRequestStatus != 1 &&
                                        item.Class_StudentStatus != 3 &&
                                        item?.Class_StudentStatus != 4 && (
                                            <div
                                                className={
                                                    homepageclasses[
                                                        "class-btns"
                                                    ]
                                                }>
                                                <div
                                                    className={
                                                        homepageclasses[
                                                            "Action-btns"
                                                        ]
                                                    }>
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                item.PricePerClass >
                                                                item.WalletAmount
                                                            )
                                                                return warningSwal(
                                                                    "Insufficient Funds",
                                                                    "Please load the wallet to join the class"
                                                                );
                                                            else if (
                                                                item.JoinUrl ==
                                                                "NA"
                                                            ) {
                                                                return warningSwal(
                                                                    "warning",
                                                                    "This link will open when the Tutor starts the session. if issue persists please refresh the page"
                                                                );
                                                            }
                                                            window.open(
                                                                item.JoinUrl,
                                                                "_blank"
                                                            );
                                                        }}>
                                                        Join Class
                                                    </button>
                                                    {/* <button>Reschedule</button> */}
                                                </div>
                                                <div
                                                    className={
                                                        homepageclasses[
                                                            "Action-btns"
                                                        ]
                                                    }>
                                                    <button
                                                        onClick={cancelClass}
                                                        custom-classid={
                                                            item.ScheduleClassID
                                                        }>
                                                        {/* 
                                                            {item.BatchSize ===
                                                        "One-to-One"
                                                            ? " Cancel Class"
                                                            : "Not Attending"}
                                                            */}
                                                        Cancel Class
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            );
                        })
                    ) : (
                        <p>No Class Found</p>
                    )}
                </div>
            </div>
            <div className={homepageclasses["Class-right"]}>
                {/* <div className={homepageclasses["news"]}>
                    <h3>Whats New ?</h3>
                    <p>
                        Hello Learner Presents Learner's Choice Awards - Spring
                        '21 Share your HL projects using this link:
                        https://hellolearner.com/contests/learners-choice-awards
                    </p>
                </div> */}
                <div className={homepageclasses["support"]}>
                    <h3>Help And Support</h3>
                    <button onClick={contactUS}>Contact Us</button>
                </div>
            </div>
        </div>
    );
};

export default Courses;
